import React from "react"
import MainTemplate from 'templates/MainTemplate'
import SEO from 'components/SEO/seo'
import FreeMaterial from 'components/FreeMaterials/Free/Free';
import {StyledFreeMaterials, StyleWrapper, StyledInner, StyledTitile } from 'components/FreeMaterials/FreeMaterials.styled'



const FreeMaterialPage = () => (
<MainTemplate>
<SEO title="Poradniki metodyczne" />
    <StyledFreeMaterials>
        <StyleWrapper>

        <StyledTitile><h1><strong>Roczny rozkład materiału</strong></h1></StyledTitile>
            <StyledInner>
              <FreeMaterial category="Przedszkole"/>
              <FreeMaterial category="Szkoła Podstawowa"/>
              <FreeMaterial category="Liceum/Technikum"/>
            </StyledInner>

        </StyleWrapper>
    </StyledFreeMaterials>

</MainTemplate>
)

export default FreeMaterialPage
