import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
// import IconDoc from "assets/images/icdoc.svg";

const StyleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    width: 30%;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 15px;
  }
`;
const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.font.size.s};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  color: ${({ theme }) => theme.blackfont};
  font-weight: 600;
`;

const H3 = styled.div`
  color: ${({ isBlue }) =>
    isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
  font-size: ${({ theme }) => theme.font.size.xxm};
  margin-top: ${({ isTop }) => (isTop ? "25px" : "10px")};
  margin-bottom: 10px;
  font-weight: 600;
`;

const StyledP = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.blackfont};
  margin-left: ${({ isLeft }) => (isLeft ? "60px" : "4px")};
`;

const Img = styled.img`
  width: ${({ isLeft }) => (isLeft ? "200px" : "25px")};
  height: ${({ isLeft }) => (isLeft ? "280px" : "20px")};
  margin-top: 0;
`;
const LinksHead = styled.div`
  width: 100%;
  height: auto;
padding-bottom: 10px;
  
  div{
    display: flex;
  }
`;

const FreeMaterial = ({ category }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsFreematerial(sort: { fields: sort }) {
        nodes {
          id
          categoryfr
          title
          link
            pdf
            linkModular {
                id
                link
            }
          turnofffree
        }
      }
    }
  `);

  return (
    <StyleWrapper>
      <H3 isBlue>{category}</H3>

      {data.allDatoCmsFreematerial.nodes
        .filter((Fre) => Fre.categoryfr === category)
        .map((Fre) => (
          <StyledDiv key={Fre.id}>
            <StyledH2>{Fre.title}</StyledH2>

            <LinksHead>
              {!Fre.turnofffree ? (
                <>
                  <div>
                    {Fre.pdf ?
                <Img
                  src="https://old.wydawnictwokatechetyczne.pl/assets/icpdf.png"
                  alt="icon"
                /> :
                      <Img
                        src="https://old.wydawnictwokatechetyczne.pl/assets/icdoc.png"
                        alt="icon"
                      />}

              <StyledP dangerouslySetInnerHTML={{ __html: Fre.link }} />
                  </div>


                  {Fre.linkModular ? <>{Fre.linkModular.map((lm) => (
                    <div key={lm.id}>

                      <Img
                        src="https://old.wydawnictwokatechetyczne.pl/assets/icdoc.png"
                        alt="icon"
                      />

                      <StyledP dangerouslySetInnerHTML={{ __html: lm.link }} />
                    </div>
                    ))}</> : null}
                {/*{Fre.linkModular ? (<div><Img
                    src="https://old.wydawnictwokatechetyczne.pl/assets/icdoc.png"
                    alt="icon"
                  />

                  <StyledP dangerouslySetInnerHTML={{ __html: Fre.linkModular.linkl }} /></div>): null}*/}
                </>
                ): <StyledP>bd.</StyledP>}
              {/* <IconDoc /> */}
            </LinksHead>
          </StyledDiv>
        ))}
    </StyleWrapper>
  );
};
FreeMaterial.propTypes = {
  category: PropTypes.string.isRequired,
};
export default FreeMaterial;
